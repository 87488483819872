import React, {useState, useCallback} from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {Hidden} from "@mui/material";
import {TabPicker} from "../components/TabPicker";
import {PackageCard} from "../components/PackageCard";
import {PackageCardItem} from "../components/PackageCardItem";
import {TicketsCounter} from "../components/TicketsCounter";
import { useWalletConnectorContext } from '../services/walletContext';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/scrollbar/scrollbar.min.css'

import ring from '../assets/svg/ring.svg'
import car from '../assets/svg/car.svg'
import cocktail from '../assets/svg/cocktail.svg'
import party from '../assets/svg/party.svg'
import kayak from '../assets/svg/kayak.svg'

import includes1 from '../assets/img/includes1.png'
import includes2 from '../assets/img/includes2.png'
import includes3 from '../assets/img/includes3.png'
import includes4 from '../assets/img/includes4.png'
import includes5 from '../assets/img/includes5.png'

export const Packages = () => {

    const { connect, payUsdt, address } = useWalletConnectorContext();

    const [tab, setTab] = useState(2)
    const handlePayUsdt = useCallback((amount) => {
        if(!address) {
            connect('MetaMask', 'Ethereum');
            return;
        }

        payUsdt(amount);
    }, [address, connect, payUsdt])

    return (
        <section className="packages" id="packages">
            <TabPicker onClick={setTab}/>
            {/*<div className="packages__bg">*/}
                <Container disableGutters>
                    <div className="packages__tab">
                        <Hidden smDown>
                            <div className="packages__tab__cards-row">
                                <PackageCard type="gold" text="Deluxe Room or Similar">
                                    <PackageCardItem onClick={() => handlePayUsdt(7500)} persons={1} price="$7,500"/>
                                    <PackageCardItem onClick={() => handlePayUsdt(13500)} persons={2} price="$13,500"/>
                                </PackageCard>
                                <PackageCard type="platinum" text="Luxury Suite or Similar">
                                    <PackageCardItem onClick={() => handlePayUsdt(8500)} persons={1} price="$8,500"/>
                                    <PackageCardItem onClick={() => handlePayUsdt(15300)} persons={2} price="$15,300"/>
                                </PackageCard>
                                <PackageCard type="vip" text="Reserve Suite or Similar">
                                    <PackageCardItem onClick={() => handlePayUsdt(10000)} persons={1} price="$10,000"/>
                                    <PackageCardItem onClick={() => handlePayUsdt(18000)} persons={2} price="$18,000"/>
                                </PackageCard>
                            </div>
                        </Hidden>

                        <Hidden smUp>
                            <div className="swiper__wrapper">
                                <Swiper
                                    spaceBetween={15}
                                    slidesPerView={1.3}
                                    // slidesPerView={'auto'}
                                    loop={false}
                                    centeredSlides={true}
                                    setWrapperSize={true}
                                    grabCursor={true}

                                >
                                    <SwiperSlide>
                                        <PackageCard type="gold" text="Deluxe Room or Similar">
                                            <PackageCardItem onClick={() => handlePayUsdt(7500)} persons={1} price="$7,500"/>
                                            <PackageCardItem onClick={() => handlePayUsdt(13500)} persons={2} price="$13,500"/>
                                        </PackageCard>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <PackageCard type="platinum" text="Luxury Suite or Similar">
                                            <PackageCardItem onClick={() => handlePayUsdt(8500)} persons={1} price="$8,500"/>
                                            <PackageCardItem onClick={() => handlePayUsdt(15300)} persons={2} price="$15,300"/>
                                        </PackageCard>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <PackageCard type="vip" text="Reserve Suite or Similar">
                                            <PackageCardItem onClick={() => handlePayUsdt(10000)} persons={1} price="$10,000"/>
                                            <PackageCardItem onClick={() => handlePayUsdt(18000)} persons={2} price="$18,000"/>
                                        </PackageCard>
                                    </SwiperSlide>
                                </Swiper>
                            </div>

                        </Hidden>

                    </div>
            </Container>
            {/*<TicketsCounter number={47}/>*/}
            <Container>
                <h2 className="packages__title">INCLUDES</h2>
                <Grid container columns={10}>
                    <Grid item md={2} xs={5}>
                        <div className="includes__row__item">
                            <img src={includes1} className="includes__row__item__icon"/>
                            <p className="includes__row__item__text">
                                Hotel room + room service
                            </p>
                        </div>
                    </Grid>
                    <Grid item md={2} xs={5}>
                        <div className="includes__row__item">
                            <img src={includes2} className="includes__row__item__icon"/>
                            <p className="includes__row__item__text">
                                All inclusive Food & Beverages including alcohol
                            </p>
                        </div>
                    </Grid>
                    <Grid item md={2} xs={5}>
                        <div className="includes__row__item">
                            <img src={includes3} className="includes__row__item__icon"/>
                            <p className="includes__row__item__text">
                                All Activities
                            </p>
                        </div>
                    </Grid>
                    <Grid item md={2} xs={5}>
                        <div className="includes__row__item">
                            <img src={includes4} className="includes__row__item__icon"/>
                            <p className="includes__row__item__text">
                                All Entertainment
                            </p>
                        </div>
                    </Grid>
                    <Grid item md={2} xs={10}>
                        <div className="includes__row__item">
                            <img src={includes5} className="includes__row__item__icon"/>
                            <p className="includes__row__item__text">
                                Transportation
                            </p>
                        </div>
                    </Grid>
                </Grid>

            </Container>
            {/*<p className="discount-info">*/}
            {/*    Club Members = 20% discount off face value*/}
            {/*</p>*/}
            {/*</div>*/}

        </section>
    )
}
