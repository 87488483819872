import React from 'react'
import {Container} from "@mui/material";
import Grid from '@mui/material/Grid';
import {Hidden} from "@mui/material";

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'

// Import Swiper style
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/scrollbar/scrollbar.min.css'

export const Trip = () => {

    return (
        <section className="trip" id="trip-itinerary">
            <Container>
                <h2 className="title">Trip Itinerary</h2>
                <Hidden mdDown>
                    <div className="trip__wrapper">

                        <div className="trip__day">
                            <Grid container>
                                <Grid item sm={4} xs={12}>
                                    <div className="trip__day__title">
                                        <p className="trip__day__title__text">Day</p>
                                        <p className="trip__day__title__number">1</p>
                                    </div>
                                </Grid>
                                <Grid item sm={8} xs={2}>
                                    <div className="trip__day__table__wrapper">
                                        <table>
                                            <tr valign='top'>
                                                <td width="20%">
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time">3:00-5:00pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Welcome to the Experience drinks</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time">6:00-6:30pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Orientation</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time">6:30-8:00pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Welcome Dinner and Drinks</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time">8:00-10:00pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Team Building and Networking</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        <div className="trip__day">
                            <Grid container>
                                <Grid item sm={8} xs={2}>
                                    <div className="trip__day__table__wrapper">
                                        <table>
                                            <tr valign='top'>
                                                <td width="20%">
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time_yellow">11:00-12:30pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Brunch</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time_yellow">1:00-3:30pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Workshop - What Are The Biggest Issues Facing Crypto Adoption</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time_yellow">4:00-6:00pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Activities on site (Massage, Jet Ski, Parasailing, Tubing, Sports, Chill)</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time_yellow">7:00-8:30pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Dinner with the group</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time_yellow">9:30-2:00am</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Team building and Networking Pub Crawl
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </Grid>


                                <Grid item sm={4} xs={12}>
                                    <div className="trip__day__title">
                                        <p className="trip__day__title__text">Day</p>
                                        <p className="trip__day__title__number">2</p>
                                    </div>
                                </Grid>

                            </Grid>
                        </div>

                        <div className="trip__day">
                            <Grid container>
                                <Grid item sm={4} xs={12}>
                                    <div className="trip__day__title">
                                        <p className="trip__day__title__text">Day</p>
                                        <p className="trip__day__title__number">3</p>
                                    </div>
                                </Grid>
                                <Grid item sm={8} xs={2}>
                                    <div className="trip__day__table__wrapper">
                                        <table>
                                            <tr valign='top'>
                                                <td width="20%">
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time">11:00-12:30pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Brunch as a Group</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time">1:00-7:00pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Yacht Day - Snorkeling, Island Hopping, Jet Skis, Chilling and Networking (entire group)</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time">8:00-9:30pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Dinner with the group</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time">10:30-2:00am</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Networking Event</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        <div className="trip__day">
                            <Grid container>
                                <Grid item sm={8} xs={2}>
                                    <div className="trip__day__table__wrapper">
                                        <table>
                                            <tr valign='top'>
                                                <td width="20%">
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time_yellow">11:30-1:00pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Brunch as a Group</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time_yellow">1:30-6:00pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Daytime Activities (Elephant Sanctuary, Jet Ski, Range, ATV… and more)</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time_yellow">7:00-9:00pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Dinner with the group and shows</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time_yellow">10:00-3:00am</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Networking Event and Party</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </Grid>


                                <Grid item sm={4} xs={12}>
                                    <div className="trip__day__title">
                                        <p className="trip__day__title__text">Day</p>
                                        <p className="trip__day__title__number">4</p>
                                    </div>
                                </Grid>

                            </Grid>
                        </div>

                        <div className="trip__day">
                            <Grid container>
                                <Grid item sm={4} xs={12}>
                                    <div className="trip__day__title">
                                        <p className="trip__day__title__text">Day</p>
                                        <p className="trip__day__title__number">5</p>
                                    </div>
                                </Grid>
                                <Grid item sm={8} xs={2}>
                                    <div className="trip__day__table__wrapper">
                                        <table>
                                            <tr valign='top'>
                                                <td width="22%">
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time">1:00-2:30pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Late Lunch</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time">All day to 5:30pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Massage, Jet Ski, Parasail, Waterskiing, Tubing, Sports</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time">7:00-8:30pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Fancy Dinner with the entire group and something special</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time">8:30-10:00pm</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Pre-Full Moon Party Fun and Games</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr valign='top'>
                                                <td>
                                                    <div className="trip__day__time__wrapper">
                                                        <p className="trip__day__time">10:00-5:00am</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="trip__day__event__wrapper">
                                                        <p className="trip__day__event">Private Full Moon Party with International DJs</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>


                    </div>
                </Hidden>

                <Hidden mdUp>

                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1.2}
                        // slidesPerView={'auto'}
                        loop={false}
                        centeredSlides={false}
                        // setWrapperSize={true}
                        grabCursor={true}

                    >
                        <SwiperSlide>
                            <div className="trip__day">
                                <Grid container>
                                    <Grid item md={4} xs={12}>
                                        <div className="trip__day__title">
                                            <p className="trip__day__title__text">Day</p>
                                            <p className="trip__day__title__number">1</p>
                                        </div>
                                    </Grid>
                                    <Grid item md={8} xs={12}>
                                        <div className="trip__day__table__wrapper">
                                            <table>
                                                <tr valign='top'>
                                                    <td width="15%">
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time">3:00-5:00pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Welcome to the Experience drinks</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time">6:00-6:30pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Orientation</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time">6:30-8:00pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Welcome Dinner and Drinks</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time">8:00-10:00pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Team Building and Networking</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trip__day">
                                <Grid container>
                                    <Grid item md={4} xs={12}>
                                        <div className="trip__day__title">
                                            <p className="trip__day__title__text">Day</p>
                                            <p className="trip__day__title__number">2</p>
                                        </div>
                                    </Grid>
                                    <Grid item md={8} xs={12}>
                                        <div className="trip__day__table__wrapper">
                                            <table>
                                                <tr valign='top'>
                                                    <td width="20%">
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time_yellow">11:00-12:30pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Brunch</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time_yellow">1:00-3:30pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Workshop - What Are The Biggest Issues Facing Crypto Adoption</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time_yellow">4:00-6:00pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Activities on site (Massage, Jet Ski, Parasailing, Tubing, Sports, Chill)</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time_yellow">7:00-8:30pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Dinner with the group</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time_yellow">9:30-2:00am</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Team building and Networking Pub Crawl
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Grid>

                                </Grid>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trip__day">
                                <Grid container>
                                    <Grid item md={4} xs={12}>
                                        <div className="trip__day__title">
                                            <p className="trip__day__title__text">Day</p>
                                            <p className="trip__day__title__number">3</p>
                                        </div>
                                    </Grid>
                                    <Grid item md={8} xs={12}>
                                        <div className="trip__day__table__wrapper">
                                            <table>
                                                <tr valign='top'>
                                                    <td width="15%">
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time">11:00-12:30pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Brunch as a Group</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time">1:00-7:00pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Yacht Day - Snorkeling, Island Hopping, Jet Skis, Chilling and Networking (entire group)</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time">8:00-9:30pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Dinner with the group</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time">10:30-2:00am</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Networking Event</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trip__day">
                                <Grid container>
                                    <Grid item md={4} xs={12}>
                                        <div className="trip__day__title">
                                            <p className="trip__day__title__text">Day</p>
                                            <p className="trip__day__title__number">4</p>
                                        </div>
                                    </Grid>
                                    <Grid item md={8} xs={12}>
                                        <div className="trip__day__table__wrapper">
                                            <table>
                                                <tr valign='top'>
                                                    <td width="20%">
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time_yellow">11:30-1:00pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Brunch as a Group</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time_yellow">1:30-6:00pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Daytime Activities (Elephant Sanctuary, Jet Ski, Range, ATV… and more)</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time_yellow">7:00-9:00pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Dinner with the group and shows</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time_yellow">10:00-3:00am</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Networking Event and Party</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Grid>

                                </Grid>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trip__day">
                                <Grid container>
                                    <Grid item md={4} xs={12}>
                                        <div className="trip__day__title">
                                            <p className="trip__day__title__text">Day</p>
                                            <p className="trip__day__title__number">5</p>
                                        </div>
                                    </Grid>
                                    <Grid item md={8} xs={12}>
                                        <div className="trip__day__table__wrapper">
                                            <table>
                                                <tr valign='top'>
                                                    <td width="15%">
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time">1:00-2:30pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Late Lunch</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time">All day to 5:30pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Massage, Jet Ski, Parasail, Waterskiing, Tubing, Sports</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time">7:00-8:30pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Fancy Dinner with the entire group and something special</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time">8:30-10:00pm</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Pre-Full Moon Party Fun and Games</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr valign='top'>
                                                    <td>
                                                        <div className="trip__day__time__wrapper">
                                                            <p className="trip__day__time">10:00-5:00am</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="trip__day__event__wrapper">
                                                            <p className="trip__day__event">Private Full Moon Party with International DJs</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </SwiperSlide>

                    </Swiper>

                </Hidden>
            </Container>
        </section>
    )
}
