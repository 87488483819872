import Container from "@mui/material/Container";

export const Video = () => {
	return (
		<section className="video__section">
			<Container>
				<div className="video__inner-container">
					<iframe width="880" height="495" src="https://www.youtube.com/embed/u1r_-fhR2Tc"
					        title="Coach K Experience V1 -   Full Highlight Film" frameBorder="0"
					        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					        allowFullScreen className="video__iframe"/>
				</div>
			</Container>
		</section>
	)
}
