import React, {useState} from 'react'
import {Hidden} from "@mui/material";

// Import Swiper React components
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';


import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/scrollbar/scrollbar.min.css'

import cpc from '../assets/svg/cpc.svg'
import add from '../assets/svg/add.svg'
import puzzle from '../assets/svg/puzzle.svg'
import bag from '../assets/svg/bag.svg'
import schema from '../assets/svg/schema.svg'
import cart from '../assets/svg/cart.svg'

import img1 from '../assets/img/what_is_happening/img1.png'
import img2 from '../assets/img/what_is_happening/img2.png'
import img3 from '../assets/img/what_is_happening/img3.png'
import img4 from '../assets/img/what_is_happening/img4.png'
import img5 from '../assets/img/what_is_happening/img5.png'
import img6 from '../assets/img/what_is_happening/img6.png'


export const WhatIsHappening = () => {


    return (
        <section className="wis" id="what-is-happening">
            <h2 className="title">What Is Happening ?</h2>
            <div className="wis__text__wrapper">
                <p className="wis__text">
                    The Coach K Experience is a once in a lifetime networking event, with some of the brightest minds in the Cryptocurrency and Blockchain ecosystem.
                    We mix networking, experiences, and team building to create lasting relationships with:
                </p>
            </div>
            <Hidden lgDown>
                <div className="wis__swiper__wrapper">
                    <Swiper
                        // spaceBetween={15}
                        slidesPerView={5}
                        // slidesPerView={'auto'}
                        loop={true}
                        centeredSlides={false}
                        setWrapperSize={true}
                        grabCursor={true}

                    >
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img1} className="wis__card__img"/>
                                <p className="wis__card__text">Projects</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img2} className="wis__card__img"/>
                                <p className="wis__card__text">Market Makers</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img3} className="wis__card__img"/>
                                <p className="wis__card__text">Entrepreneurs</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img4} className="wis__card__img"/>
                                <p className="wis__card__text">Influencers & Marketing</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img5} className="wis__card__img"/>
                                <p className="wis__card__text">Teams</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img6} className="wis__card__img"/>
                                <p className="wis__card__text">Venture Capital Funds</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </Hidden>
            <Hidden lgUp smDown>
                <div className="wis__swiper__wrapper">
                    <Swiper
                        spaceBetween={5}
                        slidesPerView={3}
                        // slidesPerView={'auto'}
                        loop={true}
                        centeredSlides={true}
                        // setWrapperSize={true}
                        grabCursor={true}

                    >
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img1} className="wis__card__img"/>
                                <p className="wis__card__text">Projects</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img2} className="wis__card__img"/>
                                <p className="wis__card__text">Market Makers</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img3} className="wis__card__img"/>
                                <p className="wis__card__text">Entrepreneurs</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img4} className="wis__card__img"/>
                                <p className="wis__card__text">Influencers & Marketing</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img5} className="wis__card__img"/>
                                <p className="wis__card__text">Teams</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img6} className="wis__card__img"/>
                                <p className="wis__card__text">Venture Capital Funds</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </Hidden>
            <Hidden smUp>
                <div className="wis__swiper__wrapper">
                    <Swiper
                        spaceBetween={5}
                        slidesPerView={1.6}
                        // slidesPerView={'auto'}
                        loop={true}
                        centeredSlides={true}
                        // setWrapperSize={true}
                        grabCursor={true}

                    >
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img1} className="wis__card__img"/>
                                <p className="wis__card__text">Projects</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img2} className="wis__card__img"/>
                                <p className="wis__card__text">Market Makers</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img3} className="wis__card__img"/>
                                <p className="wis__card__text">Entrepreneurs</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img4} className="wis__card__img"/>
                                <p className="wis__card__text">Influencers & Marketing</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img5} className="wis__card__img"/>
                                <p className="wis__card__text">Teams</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="wis__card">
                                <img src={img6} className="wis__card__img"/>
                                <p className="wis__card__text">Venture Capital Funds</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </Hidden>

            <div className="wis__text__wrapper">
                <p className="wis__text">
                    This is the ultimate networking experience giving the opportunity of true connections with other leaders in the space all while having the time of your life.
                </p>
            </div>
            <p className="wis__greeting">
                Welcome To The Coach K Experience!
            </p>
        </section>
    )
}
