import React from 'react'

export const Massage = () => {


    return (
        <section className="massage">
        </section>
    )
}
