import React from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import coach from '../assets/img/coach.png'

export const Coach = () => {

	return (
		<section className="coach">
			<Container>
				<Grid container>
					<Grid item md={7} xs={12}>
						<div className="coach__img__wrapper">
							<img src={coach} className="coach__img"/>
						</div>
					</Grid>
					<Grid item md={5} xs={12}>
						<div className="coach__info">
							<p className="coach__info__title">WHO IS COACH K</p>
							<h2 className="coach__info__name">
								Joel Kovshoff
							</h2>
							<p className="coach__info__text">
								Joel is an avid entrepreneur, trading and financial coach and industry leading advisor.
								He is known for his go-to-market strategies, creative tokenomics, and a deep partnership
								network. Coach is an advisor and friend to many projects and builders in the Crypto
								Ecosystem and the Experience is proof he brings people together.
							</p>
						</div>
					</Grid>
				</Grid>
			</Container>
		</section>
	)
}
