// import logo from './logo.svg';
// import './App.css';
import {Main} from "../sections/Main";
import {Header} from "../components/Header";
import {Packages} from "../sections/Packages";
import {PhotoCarousel} from "../sections/PhotoCarousel";
import {WhatIsHappening} from "../sections/WhatIsHappening";
import {Coach} from "../sections/Coach";
import {Network} from "../sections/Network";
import {Sponsors} from "../sections/Sponsors";
import {SponsorPerks} from "../sections/SponsorPerks";
import {Charities} from "../sections/Charities";
import {Massage} from "../sections/Massage";
import {Trip} from "../sections/Trip";
import {ScavengerHunt} from "../sections/ScavengerHunt";
import {Booking} from "../sections/Booking";
import {Partners} from "../sections/Partners";
import {Footer} from "../sections/Footer";
import {Video} from "../sections/Video";

export const LandingScreen = () => {
	return (
		<div className="landing-screen">
			<Header/>
			<Main/>
			<Video/>
			<Packages/>
			<PhotoCarousel/>
			<WhatIsHappening/>
			<Coach/>
			{/*<Activities/>*/}
			<Network/>
			<Sponsors/>
			<SponsorPerks/>
			<Charities/>
			<Massage/>
			<Trip/>
			{/*<ScavengerHunt/>*/}
			{/*<InfoCard/>*/}
			<Booking/>
			<Partners/>
			<Footer/>
		</div>
	);
}
